@tailwind base;
@tailwind components;
@tailwind utilities;

iframe#webpack-dev-server-client-overlay{display:none!important}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgb(218, 218, 179);
    border-radius: 6px;
    scrollbar-width: 50px;
  }

::-moz-selection { /* Code for Firefox */
  color: black;
  background: rgb(233, 222, 148);
}

::selection {
  color: black;
  background: rgb(233, 222, 148);
}

body { 
  overflow:hidden 
}

.cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: 100%;
}

.contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
    min-width: 100%;
}

.scale-down {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    min-width: 100%;
}

@media (min-width:1000px){
    .ck-editor {
        min-width: 0px !important;
        max-width: 45vw !important;
        position: inherit !important;
    }

    .ck-content {
        padding-left: 20px !important;
    }
}

@media (max-width:1000px){
    .ck-editor {
        min-width: 0px !important;
        max-width: 70vw !important;
        position: inherit !important;
    }
}

@keyframes bounce-x {
    0%, 100% {
        transform: translateX(40%);
        animation-timing-function: cubic-bezier(0.4,0,1,1);
    }
    50% {
        transform: none;
        animation-timing-function: cubic-bezier(0,0,0.2,1);
    }
  }
  .animate-bounce-x {
    animation: bounce-x 3s infinite;
  }

  .dropdown-button {
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-bottom-width: 1px;
    border-color: rgb(52, 52, 59);
    background-color: rgb(10, 10, 12);
    padding: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    cursor: pointer;
    color: rgb(255 255 255);
    white-space: nowrap;
  }

  .dropdown-button:hover {
    color: rgb(224, 205, 157);
}

.text-outline {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}